<template>
  <div class="wisdom-meteo">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'WisdomMeteo',
  created() {
    console.log('create wisdommeteo');
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    color: #666;
  }
</style>